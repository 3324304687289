import React, { useState } from "react";
import Logo from "../Logo";
import { useLocation } from "react-router-dom";

const Topbar = ({
  handleScrollToSection,
}: {
  handleScrollToSection: () => void;
}) => {
  const [navBar, setNavBar] = useState(false);

  const { pathname } = useLocation();

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavBar(true);
      return;
    }
    setNavBar(false);
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <div
      className={`fixed z-50 w-full ${navBar || pathname !== "/" ? "bg-black py-2" : "bg-transparent"}`}
    >
      <div className="container mx-auto xl:px-0 px-8">
        <div className="flex w-full items-center justify-between">
          <Logo />
          <button
            className="border-red-500 border hover:bg-red-500 text-gray-100 rounded-full py-2 px-5 text-md"
            onClick={handleScrollToSection}
          >
            View our Policies
          </button>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
