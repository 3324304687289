import React from "react";
import RightsText from "../../components/RightsText";

const OurPolicy = () => {
  return (
    <div>
      <div className="container mx-auto">
        <p className="text-lg leading-8">
          <h2 className="font-extrabold text-center text-5xl text-red-500 mb-2">
            Our Policy
          </h2>
          <br /> <span className="font-bold">Mess Free Charts</span> does
          business in an organized, professional manner to assure that you not
          only get what you need, but that you know exactly what to expect at
          every step along the way. <br />
          We provide written assurance regarding your privacy online - as well
          as the complete confidentiality of the business relationship.
          <br /> The satisfaction of our clients is of paramount importance to
          us and we structure everything about our business to assure that our
          clients are completely satisfied. <br /> Remember, only you are
          ultimately responsible for your trading success - please review the
          CFTC-required legal disclaimer before you undertake any live trading
          activities.
        </p>
        <RightsText />
      </div>
    </div>
  );
};

export default OurPolicy;
