import React from "react";
import NinjaTrader from "../../../assets/Ninjatrader.png";
import Reveal from "../../../components/Reveal";

const companyLogos = [
  {
    imgSrc: NinjaTrader,
    href: "https://ninjatraderecosystem.com/",
  },
];

const Companies = () => {
  return (
    <div className="bg-gray-50">
      <div className="container mx-auto w-full flex items-center justify-center p-16 flex-col">
        <div>
          <Reveal>
            <p className="text-red-500 font-black text-4xl xl:text-5xl">
              Our Partners
            </p>
          </Reveal>
        </div>
        <div>
          {companyLogos.map((item, index) => (
            <Reveal
              key={`${index}-logo`}
              className="w-full flex items-center justify-center"
            >
              <a
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                className="w-full flex items-center justify-center"
              >
                <img
                  src={item.imgSrc}
                  alt={`${index}-logo`}
                  className="w-[250px] aspect-square object-contain"
                />
              </a>
            </Reveal>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Companies;
