import React from "react";
import RightsText from "../../components/RightsText";

const CFTCDisclaimer = () => {
  return (
    <div>
      <div className="container mx-auto">
        <p className="text-lg leading-8">
          <h2 className="font-extrabold text-center text-5xl text-red-500 mb-2">
            CFTC Required Disclaimer
          </h2>
          <br />
          <p>
            <h4 className="font-bold text-center text-red-500 text-xl mb-2">
              Trading involves risk
            </h4>
            All financial trading involves significant risk.
          </p>
          <br />
          <p>
            <h4 className="font-bold text-center text-red-500 text-xl mb-2">
              You are solely responsible for all trading profit or loss
            </h4>
            Only you are responsible for the trades you take, including trades
            taken through automated trading systems you may in any manner
            employ. You alone are ultimately and completely responsible for all
            testing and quality assurance of all trading systems, methods and
            technologies you may employ, and you alone bear the full profit or
            loss of any and all trading activities you may undertake.
          </p>
          <br />
          <p>
            <h4 className="font-bold text-center text-red-500 text-xl mb-2">
              Risk of trading futures, options, equities, and forex
            </h4>
            There is a significant risk of loss in futures, forex, equities, and
            options trading - including trades taken online. Trade only with
            capital you can afford to lose. Past performance in not necessarily
            indicative of future results. Nothing on this website or that we may
            separately convey is intended to be a recommendation to buy or sell
            any market or instrument. All information has been obtained from
            sources and methods which are believed to be reliable, but accuracy
            and thoroughness cannot be guaranteed. You alone are solely
            responsible for how to use the information and for the results.
            <b>Mess Free Charts</b> does not guarantee the accuracy or
            completeness of any information or any analysis based thereon.
          </p>
          <br />
          <p>
            <h4 className="font-bold text-center text-red-500 text-xl mb-2">
              Hypothetical performance
            </h4>
            <b> Mess Free Charts</b> may collect and use Users' personal
            information for the following purposes:
            <p>
              Hypothetical performance results have many inherent limitations,
              some of which are described below. No representation is being made
              that any account will or is likely to achieve profits or losses
              similar to those shown. In fact, there are frequently sharp
              differences between hypothetical performance results and the
              actual results subsequently achieved by any particular trading
              program.
            </p>
            <br />
            <p>
              One of the limitations of hypothetical performance results is that
              they are generally prepared with the benefit of hindsight. In
              addition, hypothetical trading does not involve financial risk,
              and no hypothetical trading record can completely account for the
              impact of financial risk in actual trading. For example, the
              ability to withstand losses or to adhere to a particular trading
              program in spite of trading losses are material points which can
              also adversely affect actual trading results. There are numerous
              other factors related to the markets in general or to the
              implementation of any specific trading program which cannot be
              fully accounted for in the preparation of hypothetical performance
              results and all of which can adversely affect actual trading
              results.
            </p>
            <br />
            <p>
              <b>Mess Free Charts</b> does not trade actual accounts for
              clients. Because there are no actual trading results to compare to
              any hypothetical performance results to in advance of live
              trading, clients should be particularly wary of placing undue
              reliance on hypothetical performance results.
            </p>
            <br />
            <p>
              Commission Rule 4.41(c)(1) applies to "any publication,
              distribution or broadcast of any report, letter, circular,
              memorandum, publication, writing, advertisement or other
              literature…." Commission Rule 4.41(b) prohibits any person from
              presenting the performance of any simulated or hypothetical
              futures account or futures interest of a CTA, unless the
              presentation is accompanied by a disclosure statement. The
              statement describes the limitations of simulated or hypothetical
              futures trading as a guide to the performance that a CTA is likely
              to achieve in actual trading.
            </p>
          </p>
          <br />
          <p>
            <h4 className="font-bold text-center text-red-500 text-xl mb-2">
              Additional risk disclosure for systems traders
            </h4>
            Commission Rule 4.41(b)(1)(I) hypothetical or simulated performance
            results have certain inherent limitations. Unlike an actual
            performance record, simulated results do not represent actual
            trading. Also, since the trades have not actually been executed, the
            results may have under-compensated or over-compensated for the
            impact, if any, of certain market factors, such as lack of
            liquidity. Simulated trading programs in general are also subject to
            the fact that they are designed with the benefit of hindsight. No
            representation is being made that any account will or is likely to
            achieve profits or losses. There have been no promises, guarantees
            or warranties suggesting that any trading will result in a profit or
            will not result in a loss.
          </p>
        </p>
        <RightsText />
      </div>
    </div>
  );
};

export default CFTCDisclaimer;
