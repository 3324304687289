import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const policies = [
    {
      label: "Home",
      url: "/",
    },
    {
      label: "Our Policy",
      url: "/our-policy",
    },
    {
      label: "Terms of Service",
      url: "/terms-of-service",
    },
    {
      label: "Refund Policy",
      url: "/refund-policy",
    },
    {
      label: "Privacy Policy",
      url: "/privacy-policy",
    },
    {
      label: "Confidentiality Policy",
      url: "/confidentiality-policy",
    },
    {
      label: "CFTC Disclaimer",
      url: "/cftc-disclaimer",
    },
  ];

  return (
    <div className="bg-[#333333]">
      <div className="container mx-auto py-16 text-gray-200 xl:px-0 px-8">
        <div className="flex flex-col gap-6">
          <p className="leading-7 xl:text-left text-justify">
            <span className="text-red-400 font-bold text-lg">
              Risk Disclosure:
            </span>
            <br /> Futures trading contains substantial risk and is
            not for every investor. An investor could potentially lose all or
            more than the initial investment. Risk capital is money that can be
            lost without jeopardizing ones’ financial security or life style.
            Only risk capital should be used for trading and only those with
            sufficient risk capital should consider trading. Past performance is
            not necessarily indicative of future results.
          </p>
          <p className="leading-7 xl:text-left text-justify">
            <span className="text-red-400 font-bold text-lg">
              Hypothetical Performance Disclosure:
            </span>
            <br /> Hypothetical performance results have many inherent
            limitations, some of which are described below. no representation is
            being made that any account will or is likely to achieve profits or
            losses similar to those shown; in fact, there are frequently sharp
            differences between hypothetical performance results and the actual
            results subsequently achieved by any particular trading program. One
            of the limitations of hypothetical performance results is that they
            are generally prepared with the benefit of hindsight. In addition,
            hypothetical trading does not involve financial risk, and no
            hypothetical trading record can completely account for the impact of
            financial risk of actual trading. for example, the ability to
            withstand losses or to adhere to a particular trading program in
            spite of trading losses are material points which can also adversely
            affect actual trading results. There are numerous other factors
            related to the markets in general or to the implementation of any
            specific trading program which cannot be fully accounted for in the
            preparation of hypothetical performance results and all which can
            adversely affect trading results.
          </p>
          <div className="border-t pt-6 flex justify-between items-center flex-col-reverse gap-4 xl:flex-row">
            <div>© 2018 - 2024 Mess Free Charts.</div>
            <div className="flex gap-4 text-red-400 flex-wrap justify-center xl:justify-start">
              {policies.map((policy) => (
                <div
                  key={policy.url}
                  aria-hidden
                  onClick={() => navigate(policy.url)}
                  className="hover:text-red-500 cursor-pointer hover:underline"
                >
                  {policy.label}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
