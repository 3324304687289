import React, { useState } from "react";

const Checkout = () => {
  const [amount, setAMount] = useState(0);
  const options = [
    {
      label: "Choose...",
      price: 0,
    },
    {
      label: "High Low Limiter",
      price: 100,
    },
    {
      label: "Pivot Fib Catcher Basic",
      price: 150,
    },
    {
      label: "Pivot Fib Catcher",
      price: 200,
    },
    {
      label: "Custom EMA Lines - Time Frame Independant",
      price: 250,
    },
    {
      label: "Custom Line Cross",
      price: 400,
    },
    {
      label: "Reversal Bar Region Painter",
      price: 500,
    },
    {
      label: "Custom Report - 1 Hour (120/hr)",
      price: 120,
    },
    {
      label: "Custom Report - 2 Hours (120/hr)",
      price: 240,
    },
  ];
  return (
    <div>
      <h2 className="font-extrabold text-center text-5xl text-red-500 mb-2">
        Select Your Product
      </h2>
      <form>
        <div className="flex flex-col gap-5 mt-10">
          <select
            className="border px-4 py-3 rounded-xl"
            onChange={(e) => setAMount(+e.target.value)}
          >
            {options.map((opt) => (
              <option key={opt.price} value={opt.price}>
                {opt.label}
              </option>
            ))}
          </select>
          <div className="text-gray-500">
            <p>
              Amount (in Dollars): <span className="font-bold">${amount}</span>
            </p>
          </div>
          <input
            type="email"
            name="email"
            placeholder="Your email address..."
            className="border px-4 py-3 rounded-xl"
          />
          <button className="font-bold bg-red-500 text-gray-50 rounded-full py-3">
            Pay
          </button>
        </div>
      </form>
    </div>
  );
};

export default Checkout;
