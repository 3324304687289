import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import OurPolicy from "./pages/Our Policy";
import Layout from "./components/Layout";
import TermsOfService from "./pages/TermsOfService";
import RefundPolicy from "./pages/RefundPolicy";
import ConfidentialityPolicy from "./pages/ConfidentialityPolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CFTCDisclaimer from "./pages/CFTCDisclaimer";
import Checkout from "./pages/Checkout";
import ScrollToTop from "./components/ScollToTop";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Layout>
          <div>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/our-policy" element={<OurPolicy />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/refund-policy" element={<RefundPolicy />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/confidentiality-policy"
                element={<ConfidentialityPolicy />}
              />
              <Route path="/cftc-disclaimer" element={<CFTCDisclaimer />} />
            </Routes>
          </div>
        </Layout>
      </BrowserRouter>
    </div>
  );
};

export default App;
