import React from "react";
import RightsText from "../../components/RightsText";

const ConfidentialityPolicy = () => {
  return (
    <div>
      <div className="container mx-auto">
        <p className="text-lg leading-8">
          <h2 className="font-extrabold text-center text-5xl text-red-500 mb-2">
            Confidentiality Policy
          </h2>
          <br />
          <p>
            <b>Mess Free Charts</b> specializes in researching, building,
            providing, and supporting technologies related to financial market
            trading. No one cares as much about confidentiality as trading
            professionals do, and supporting trading-related business is what we
            do. We understand and respect the confidential nature of every
            business relationship.
          </p>
          <br />
          <p>
            Our assumption from the start of every communication you have with
            us is that all client-specific information will be kept completely
            compartmentalized and secure, and we maintain strict measures
            in-house to assure that confidentiality is maintained at all times.
          </p>
          <br />
          <p>
            We provide easy-to-understand written assurance for clients of the
            confidential nature of our business relationships. Not only will no
            non-public information shared within the context of your business
            relationship with us be disclosed to anyone else for any reason, but
            the relationship itself is considered by default to be a
            confidential one.
          </p>
          <br />
          <p>
            If you are looking for information on the steps we take to guard
            your privacy online, see our privacy policy.
          </p>
        </p>
        <RightsText />
      </div>
    </div>
  );
};

export default ConfidentialityPolicy;
