import React from "react";
import HighLowLimiter from "../../../assets/HighLowLimiter.png";
import PivotFibCatcherBasic from "../../../assets/PivotFibCatcherBasic.png";
import PivotFibCatcher from "../../../assets/PivotFibCatcher.png";
import CustomEmma from "../../../assets/CustomEMAAnyTimeFrame.png";
import CustomeLineCross from "../../../assets/CustomLineCross.png";
import ReversalBarRegionPainter from "../../../assets/ReversalRegionPainter.png";
import Reveal from "../../../components/Reveal";
import useMediaQuery from "../../../hooks/useMediaQuery";

const Products = () => {
  const isDesktop = useMediaQuery("(min-width: 1280px)");
  const products = [
    {
      price: "100",
      title: "High Low Limiter",
      subtitle: "See where the market has been and might go again",
      imgUrl: HighLowLimiter,
    },
    {
      price: "150",
      title: "Pivot Fib Catcher Basic",
      subtitle: "See the trend and potential retracement or extension regions",
      imgUrl: PivotFibCatcherBasic,
    },
    {
      price: "200",
      title: "Pivot Fib Catcher",
      subtitle:
        "See the trend and potential retracement or extension regions. Abilty to stack EMA lines.",
      imgUrl: PivotFibCatcher,
    },
    {
      price: "250",
      title: "Custom EMA Lines - Time Frame Independant",
      subtitle:
        "Our Custom EMA Line indicator will allow you to put 1 minute EMA lines for any time frame. Excellent for finding support and resistance levels.",
      imgUrl: CustomEmma,
    },
    {
      price: "400",
      title: "Custom Line Cross",
      subtitle:
        "Explore strategy ideas by combining indicators and having markup and region coloring directly on the chart.",
      imgUrl: CustomeLineCross,
    },
    {
      price: "500",
      title: "Reversal Bar Region Painter",
      subtitle: "This indicator will help you catch trends. Follow that arrow!",
      imgUrl: ReversalBarRegionPainter,
    },
  ];
  return (
    <div className="bg-red-500">
      <Reveal>
        <div className="container mx-auto w-full flex flex-col gap-20 items-center justify-center p-16">
          <div>
            <p className="text-white font-black text-4xl xl:text-5xl">
              Our Products
            </p>
          </div>
          <div className="grid grid-cols-1 gap-20 w-full">
            {products.map((product, index) => (
              <Reveal
                className="flex xl:justify-between w-full gap-5 xl:gap-20 flex-col xl:flex-row"
                style={{
                  flexDirection: isDesktop
                    ? index % 2 !== 0
                      ? "row-reverse"
                      : "row"
                    : "column",
                }}
                key={product.title}
              >
                <div>
                  <img
                    src={product.imgUrl}
                    alt={product.title}
                    className="rounded-3xl h-52 sm:h-auto w-full xl:w-[800px] shadow-2xl"
                  />
                </div>
                <div className="flex-1">
                  <h2 className="text-white font-black text-3xl">
                    {product.title}
                  </h2>
                  <p className="text-lg text-white">{product.subtitle}</p>
                  <div className="text-8xl text-white font-black relative">
                    <div className="text-4xl absolute -left-7">$</div>
                    <div>{product.price}</div>
                  </div>
                </div>
              </Reveal>
            ))}
          </div>
        </div>
      </Reveal>
    </div>
  );
};

export default Products;
