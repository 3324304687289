import React from "react";
import RightsText from "../../components/RightsText";

const RefundPolicy = () => {
  return (
    <div>
      <div className="container mx-auto">
        <p className="text-lg leading-8">
          <h2 className="font-extrabold text-center text-5xl text-red-500 mb-2">
            Refund Policy
          </h2>
          <br />
          Due to the ease with which digital products may be copied and stored,
          we will <b>NOT</b> issue refunds of any kind.
          <br />
          We advise visitors to carefully review our policies. Due to the nature
          of our proprietary software products,{" "}
          <b>ALL SALES ARE FINAL & NO REFUNDS WILL BE ISSUED FOR ANY REASON</b>.
          It is the sole responsibility of the user to be fully aware of this
          policy prior to making a purchase. In the event you have any questions
          regarding any of our policies. We strongly encourage you prior to
          purchasing one of our products to contact us and allow us to help you
          address any concerns. Therefore, please <b>CONTACT US</b> if you want
          to ask questions before making a purchase.
        </p>
        <RightsText />
      </div>
    </div>
  );
};

export default RefundPolicy;
