import React from "react";
import Reveal from "../../../components/Reveal";

const Hero = ({
  handleScrollToSection,
}: {
  handleScrollToSection: () => void;
}) => {
  return (
    <div className="w-full h-[100vh] bg-[url('assets/bg.jpg')] bg-cover bg-center relative">
      <div className="absolute inset-0 bg-black opacity-80"></div>
      <div className="relative z-10 flex items-center h-full container mx-auto px-8 xl:px-0">
        <Reveal>
          <div className="flex flex-col gap-5 items-start">
            <h1 className="text-5xl xl:text-[64px] font-black text-red-500">
              Mess Free Charts (MFC)
            </h1>
            <p className="font-bold text-white text-3xl xl:text-[44px] leading-tight">
              Let our software help you become more <br />
              consistent with your trading. <br />
            </p>

            <p className="text-white xl:w-2/4 w-full leading-snug font-light">
              We were formed with a simple objective in mind. Help clients find
              deals in the market. The deals are always present. With our
              software we show clients how to find the deals, enabling
              consistent trades regardless of market moves.
            </p>

            <div className="border-t-[4px] pt-7 border-">
              <button
                className="bg-red-500 hover:bg-red-400 text-gray-100 rounded-full py-3 px-5 border-0"
                onClick={handleScrollToSection}
              >
                Browse our products
              </button>
            </div>
          </div>
        </Reveal>
      </div>
    </div>
  );
};

export default Hero;
