import React from "react";
import RightsText from "../../components/RightsText";

const PrivacyPolicy = () => {
  return (
    <div>
      <div className="container mx-auto">
        <p className="text-lg leading-8">
          <h2 className="font-extrabold text-center text-5xl text-red-500 mb-2">
            Privacy Policy
          </h2>
          <br />
          <p>
            <b>Mess Free Charts</b> values and respects your privacy. Accustomed
            to working with financial market trading and business professionals,
            we maintain strict privacy standards on your behalf, and we detail
            them in this Privacy Policy, which governs the manner in which Mess
            Free Charts collects, uses, maintains and discloses information
            collected from users (each, a "User") of the{" "}
            <a
              href="https://www.MessFreeCharts.com"
              target="_blank"
              rel="noreferrer"
              className="text-blue-500 hover:underline"
            >
              https://www.MessFreeCharts.com
            </a>{" "}
            website ("Site"). This privacy policy applies to the Site and all
            products and services offered by Mess Free Charts .
          </p>
          <br />
          <p>
            <h4 className="font-bold text-center text-red-500 text-xl mb-2">
              Personal identification information
            </h4>
            We may collect personal identification information from Users in a
            variety of ways, including, but not limited to, when Users visit our
            site, register on the site, place an order, fill out a form, respond
            to a survey, and in connection with other activities, services,
            features or resources we make available on our Site. Users may be
            asked for, as appropriate, name, email address, mailing address,
            phone number. Users may, however, visit our Site anonymously. We
            will collect personal identification information from Users only if
            they voluntarily submit such information to us. Users can always
            refuse to supply personally identification information, except that
            it may prevent them from engaging in certain Site related
            activities.
          </p>
          <br />
          <p>
            <h4 className="font-bold text-center text-red-500 text-xl mb-2">
              Non-personal identification information
            </h4>
            We may collect non-personal identification information about Users
            whenever they interact with our Site. Non-personal identification
            information may include the browser name, the type of computer and
            technical information about Users means of connection to our Site,
            such as the operating system and the Internet service providers
            utilized and other similar information.
          </p>
          <br />
          <p>
            <h4 className="font-bold text-center text-red-500 text-xl mb-2">
              Web browser cookies
            </h4>
            Our Site may use "cookies" to enhance User experience. User's web
            browser places cookies on their hard drive for record-keeping
            purposes and sometimes to track information about them. User may
            choose to set their web browser to refuse cookies, or to alert you
            when cookies are being sent. If they do so, note that some parts of
            the Site may not function properly.
          </p>
          <br />
          <p>
            <h4 className="font-bold text-center text-red-500 text-xl mb-2">
              How we use collected information
            </h4>
            Mess Free Charts may collect and use Users' personal information for
            the following purposes:
            <ul className="list-disc ml-10">
              <li>
                <b>To run and operate our Site:</b> We may need your information
                display content on the Site correctly.
              </li>
              <li>
                <b>To improve customer service:</b> Information you provide
                helps us respond to your customer service requests and support
                needs more efficiently.
              </li>
              <li>
                <b>To personalize user experience:</b> We may use information in
                the aggregate to understand how our Users as a group use the
                services and resources provided on our Site.
              </li>
              <li>
                <b>To improve our Site:</b> We may use feedback you provide to
                improve our products and services.
              </li>
              <li>
                <b>To process payments:</b> We may use the information Users
                provide about themselves when placing an order only to provide
                service to that order. We do not share this information with
                outside parties except to the extent necessary to provide the
                service.
              </li>
              <li>
                <b>To run a provide a Site feature:</b> To send Users
                information they agreed to receive about topics we think will be
                of interest to them.
              </li>
              <li>
                <b>To send periodic emails:</b> We may use the email address to
                send User information and updates pertaining to their order. It
                may also be used to respond to their inquiries, questions,
                and/or other requests.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <h4 className="font-bold text-center text-red-500 text-xl mb-2">
              How we protect your information
            </h4>
            We adopt appropriate data collection, storage and processing
            practices and security measures to protect against unauthorized
            access, alteration, disclosure or destruction of your personal
            information, username, password, transaction information and data
            stored on our Site.
          </p>
          <br />
          <p>
            <h4 className="font-bold text-center text-red-500 text-xl mb-2">
              Sharing your personal information
            </h4>
            We do not sell, trade, or rent Users' personal identification
            information to others. We may share generic aggregated demographic
            information not linked to any personal identification information
            regarding visitors and users with our business partners, trusted
            affiliates and advertisers for the purposes outlined above. We may
            use third party service providers to help us operate our business
            and the Site or administer activities on our behalf, such as sending
            out newsletters or surveys. We may share your information with these
            third parties for those limited purposes provided that you have
            given us your permission.
          </p>
          <br />
          <p>
            <h4 className="font-bold text-center text-red-500 text-xl mb-2">
              Electronic newsletters
            </h4>
            If User decides to opt-in to our mailing list, they will receive
            emails that may include company news, updates, related product or
            service information, etc. If at any time the User would like to
            unsubscribe from receiving future emails, we include detailed
            unsubscribe instructions at the bottom of each email or User may
            contact us via our Site. We may use third party service providers to
            help us operate our business and the Site or administer activities
            on our behalf, such as sending out newsletters or surveys. We may
            share your information with these third parties for those limited
            purposes provided that you have given us your permission.
          </p>
          <br />
          <p>
            <h4 className="font-bold text-center text-red-500 text-xl mb-2">
              Third party websites
            </h4>
            Users may find advertising or other content on our Site that link to
            the sites and services of our partners, suppliers, advertisers,
            sponsors, licensors and other third parties. We do not control the
            content or links that appear on these sites and are not responsible
            for the practices employed by websites linked to or from our Site.
            In addition, these sites or services, including their content and
            links, may be constantly changing. These sites and services may have
            their own privacy policies and customer service policies. Browsing
            and interaction on any other website, including websites which have
            a link to our Site, is subject to that website's own terms and
            policies.
          </p>
          <br />
          <p>
            <h4 className="font-bold text-center text-red-500 text-xl mb-2">
              Changes to this privacy policy
            </h4>
            Mess Free Charts has the discretion to update this privacy policy at
            any time. When we do, we will post a notification on the main page
            of our Site. We encourage Users to frequently check this page for
            any changes to stay informed about how we are helping to protect the
            personal information we collect. You acknowledge and agree that it
            is your responsibility to review this privacy policy periodically
            and become aware of modifications.
          </p>
          <br />
          <p>
            <h4 className="font-bold text-center text-red-500 text-xl mb-2">
              Your acceptance of these terms
            </h4>
            By using this Site, you signify your acceptance of this policy. If
            you do not agree to this policy, please do not use our Site. Your
            continued use of the Site following the posting of changes to this
            policy will be deemed your acceptance of those changes.
          </p>
          <br />
          <p>
            <h4 className="font-bold text-center text-red-500 text-xl mb-2">
              Contacting us
            </h4>
            If you have any questions about this Privacy Policy, the practices
            of this site, or your dealings with this site, please contact us.
          </p>
        </p>
        <RightsText />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
