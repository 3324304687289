import React from "react";
import { FaRegHandshake } from "react-icons/fa";
import { BiLike } from "react-icons/bi";
import Reveal from "../../../components/Reveal";

const Details = () => {
  const details = [
    {
      icon: <BiLike className="w-20 h-20" />,
      title: "Why buy from us?",
      body: "Our indicators take out the guess work. Check out our products. Please send us a message using the contact us form below to get a free gift and be alerted when there are updates to our products.",
    },
    {
      icon: <FaRegHandshake className="w-20 h-20" />,
      title: "Strength",
      body: "Over 20 years of complex software design experience working with various financial industries. While we specilize with using the NinjaTrader platform, we are always open to discussions regarding other platforms.",
    },
  ];
  return (
    <div className="w-full container mx-auto flex items-center justify-center flex-col gap-20 py-16 xl:px-0 px-8">
      <Reveal>
        <div className="h-[130px]">
          <p className="font-black text-red-500 text-center text-4xl xl:text-5xl leading-snug">
            Professional Tool for the Futures <br /> Trading Industry
          </p>
        </div>
      </Reveal>
      <div>
        <Reveal>
          <div className="grid grid-cols-1 xl:grid-cols-2 w-full gap-10">
            {details.map((detail, index) => (
              <div
                key={`${detail}-${index}`}
                className="bg-red-500 text-white rounded-2xl p-8 flex flex-col gap-4 shadow-md hover:shadow-xl"
              >
                {detail.icon}
                <h2 className="font-bold text-3xl">{detail.title}</h2>
                <p className="text-justify text-lg">{detail.body}</p>
              </div>
            ))}
          </div>
        </Reveal>
      </div>
    </div>
  );
};

export default Details;
