import React from "react";
import RightsText from "../../components/RightsText";

const TermsOfService = () => {
  return (
    <div>
      <div className="container mx-auto">
        <p className="text-lg leading-8">
          <h2 className="font-extrabold text-center text-5xl text-red-500 mb-2">
            Terms of Service
          </h2>
          <br />
          <span className="font-bold">
            Upon purchasing our product(s), you agree to these terms and must
            strictly follow:
          </span>
          <ul className="list-disc ml-10">
            <li>
              You <b>CAN</b> use it/them personally on no more than one
              computer. If you require more computers please contact us.
            </li>
            <li>
              You <b>CANNOT</b> give it/them away for free (e.g. via websites,
              forums, social networks, emails).
            </li>
            <li>
              You <b>CANNOT</b> resell or include it/them in your commercial
              products/services. If you wish to partner or extend our software
              please contact us.
            </li>
          </ul>
        </p>
        <RightsText />
      </div>
    </div>
  );
};

export default TermsOfService;
