import React, { ReactNode, useRef } from "react";
import Topbar from "../Topbar";
import Footer from "../Footer";
import { useLocation } from "react-router-dom";

const Layout = ({ children }: { children: ReactNode }) => {
  const footerRef = useRef<HTMLDivElement | null>(null);
  const { pathname } = useLocation();

  const handleScrollToSection = () => {
    footerRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="flex flex-col min-h-screen">
      <Topbar handleScrollToSection={handleScrollToSection} />
      <main
        className={`flex-1 ${pathname === "/" ? "mt-0" : "mt-[88px] flex items-center justify-center py-16 px-8 xl:px-0"}`}
      >
        {children}
      </main>
      <div ref={footerRef}>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
