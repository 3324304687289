import React from "react";

const RightsText = () => {
  return (
    <p className="font-bold text-red-500 mt-10 text-center lg:text-left">
      <i>
        Mess Free Charts reserves the right to refuse service to anyone at any
        time and for any reason. All material on this website is subject to
        change at any time. This document was last updated on October 4, 2024
      </i>
    </p>
  );
};

export default RightsText;
