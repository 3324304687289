import React from "react";
import Reveal from "../../../components/Reveal";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-red-500">
      <Reveal>
        <div className="container mx-auto py-16 xl:px-0 px-8">
          <div className="flex gap-4 xl:flex-row flex-col">
            <div className="flex-1 flex flex-col gap-5 text-gray-50">
              <h2 className="text-gray-50 font-black text-5xl">Contact Us</h2>
              <p className="leading-snug">
                Please contact us for a custom quote. We charge $150 an hour and
                bill in $25 dollar increments after the first hour. We can also book a 30 minute
                phone call. Please email us to schedule a time. Once your order is placed. We will send you the
                indicator you purchased to the email address you provided.
              </p>
              <div>
                <p>
                  Call us at: 1-240-772-1650
                  <br />
                  Email us at: admin@messfreecharts.com
                  <br />
                  <br />
                  <button
                    className="border border-gray-100 rounded-full px-4 py-3 hover:border-red-400 hover:bg-red-400"
                    onClick={() => navigate("/checkout")}
                  >
                    Checkout Product
                  </button>
                </p>
              </div>
            </div>
            <div className="flex-1 flex xl:justify-end">
              <div className="bg-white rounded-xl p-5 shadow-lg xl:w-3/4 w-full">
                <p className="text-sm">
                  <span className="font-bold text-3xl text-red-500">
                    Get in Touch{" "}
                  </span>
                  <br />
                  You can reach us anytime
                </p>
                <form>
                  <div className="flex flex-col gap-5 mt-4">
                    <input
                      type="email"
                      name="email"
                      placeholder="Your email address..."
                      className="border px-4 py-3 rounded-xl"
                    />
                    <input
                      name="subject"
                      placeholder="Message subject..."
                      className="border px-4 py-3 rounded-xl"
                    />
                    <textarea
                      name="message"
                      placeholder="Message text..."
                      className="border px-4 py-3 rounded-xl"
                      rows={5}
                    />
                    <button className="font-bold bg-red-500 text-gray-50 rounded-full py-3">
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Reveal>
    </div>
  );
};

export default ContactUs;
