import React from "react";
import Josh from "../../../assets/Josh.jpg";
import Reveal from "../../../components/Reveal";

const MeetTheTeam = () => {
  return (
    <div>
      <Reveal>
        <div className="container mx-auto py-16 flex flex-col gap-16 xl:px-0 px-8">
          <div className="text-red-500 font-black text-4xl xl:text-5xl text-center">
            Meet Our Team
          </div>
          <div className="flex justify-center w-full">
            <div className="border border-red-500 rounded-3xl p-7 w-[420px] shadow-md flex flex-col gap-5 hover:border-red-800">
              <div className="flex justify-center">
                <img
                  src={Josh}
                  alt="Josh Couto"
                  className="rounded-full w-32 h-32"
                />
              </div>
              <div>
                <h2 className="text-center font-bold text-red-500 text-xl">
                  Josh Couto (CTO)
                </h2>
              </div>
              <div>
                <p className="text-center">
                  Providing simple and elegant online solutions is a passion. As
                  a software engineer and web architect, Josh has been personally creating and implementing
                  custom web applications for the past 20 years. In addition,
                  Josh has led development teams to deliver large scale
                  e-learning and data based projects, to name a few.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Reveal>
    </div>
  );
};

export default MeetTheTeam;
