import React from "react";
import { useNavigate } from "react-router-dom";

const Logo = () => {
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div
      className="font-black text-white bg-black p-3 text-5xl inline-flex items-center justify-center cursor-pointer"
      aria-hidden
      onClick={() => {
        navigate("/");
        scrollToTop();
      }}
    >
      <div>MFC</div>
    </div>
  );
};

export default Logo;
